.mat-expansion-panel:not([class*='default']) {
  color: var(--ds-neutral-900);

  &.no-collapsed-background:not([class*='mat-expanded']) {
    background: none;
    box-shadow: none;
  }

  &.no-background {
    background: none;
    box-shadow: none;
  }

  &.no-padding .mat-expansion-panel-body {
    padding: 0;
  }
}
