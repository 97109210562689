mat-nested-tree-node {
  .mat-tree-node {
    .mdc-button.mdc-primary,
    .mdc-icon-button.mdc-primary,
    .mdc-stroked-button.mdc-primary .mdc-stroked-button.mdc-focus-overlay {
      color: var(--ds-neutral-900);
      background-color: var(--ds-neutral-50);
    }
  }
  .mat-tree-node:hover {
    .mdc-button.mdc-primary:hover,
    .mdc-icon-button.mdc-primary:hover,
    .mdc-stroked-button.mdc-primary:hover {
      background-color: var(--ds-neutral-100);
    }
  }
}

.tree-container .mdc-button {
  color: var(--ds-neutral-900);
}

.mdc-stroked-button .mdc-focus-overlay,
.mdc-button.mdc-primary .mdc-button-focus-overlay {
  display: none;
}

mat-tree .mdc-icon-button {
  width: calc(1.5 * var(--ds-spacing)) !important;
  height: calc(1.5 * var(--ds-spacing)) !important;
  padding: 0 !important;
  line-height: 1.5 !important;
}
