@use 'styles/new-design/mixins/gradients' as gradients;

.mdc-button {
  &.mat-mdc-button,
  &[class*='raised'],
  &[class*='unelevated'],
  &[class*='outlined'] {
    letter-spacing: normal;
  }

  white-space: nowrap;
  text-transform: none;
  border-radius: var(--ds-border-radius) !important;
  text-align: center;

  .mat-icon {
    line-height: 1 !important;
  }

  &.mat-mdc-snack-bar-action {
    color: var(--ds-white) !important;
  }

  & mat-progress-spinner {
    display: inline-block;
  }

  &[class*='raised']:not(:disabled),
  &[class*='unelevated']:not(:disabled) {
    color: var(--ds-neutral-900);
  }

  &[class*='raised'].mat-accent,
  &[class*='raised'].mat-primary,
  &[class*='unelevated'].mat-accent,
  &[class*='unelevated'].mat-primary {
    @include gradients.inclined-gradient();
    color: var(--ds-neutral-contrast-500);
    mat-progress-spinner circle {
      stroke: var(--ds-neutral-contrast-500);
    }
    &[disabled] {
      color: var(--ds-neutral-contrast-500);
      opacity: 0.4;
    }
  }

  &[class*='outlined']:not(:disabled) {
    border-color: var(--ds-neutral-900) !important;

    &.mat-accent,
    &.mat-primary,
    &.mat-warn,
    &.mat-unthemed {
      color: var(--ds-neutral-900);
    }
  }

  &.mat-mdc-button:not(:disabled) {
    color: var(--ds-neutral-900);

    &.mat-accent,
    &.mat-primary,
    &.mat-warn {
      color: var(--mdc-text-button-label-text-color, inherit) !important;
    }
  }

  &[class*='raised'].mat-warn,
  &[class*='unelevated'].mat-warn {
    @include gradients.inclined-gradient(
      var(--ds-warning-900),
      var(--ds-warning-500)
    );
    color: var(--ds-neutral-contrast-500);
    mat-progress-spinner circle {
      stroke: var(--ds-neutral-contrast-500);
    }
    &[disabled] {
      color: var(--ds-warning-contrast-500);
      opacity: 0.4;
    }
  }

  &[back-button] {
    margin-top: var(--ds-spacing);
    margin-bottom: var(--ds-spacing);
  }
}

.mdc-button .pending-spinner {
  margin-right: calc(0.5 * var(--ds-spacing));
}

.mat-mdc-calendar-body-selected {
  color: var(--ds-neutral-10);
}

html .mat-mdc-mini-fab.mat-warn,
html .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: var(--ds-neutral-10);
}
