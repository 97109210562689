:root {
  .mat-mdc-chip.mat-mdc-standard-chip {
    $color: var(--ds-neutral-contrast-500);
    --mdc-chip-label-text-color: #{$color};
    --mdc-chip-disabled-label-text-color: #{$color};
    --mdc-chip-with-icon-icon-color: #{$color};
    --mdc-chip-with-icon-disabled-icon-color: #{$color};
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #{$color};
    --mdc-chip-with-trailing-icon-trailing-icon-color: #{$color};
    --mdc-chip-with-icon-selected-icon-color: #{$color};
    background: var(--ds-neutral-300);
  }
}
