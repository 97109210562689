:root {
  .mat-mdc-select {
    font-weight: normal;
    line-height: 1.1;
  }
  .mat-mdc-select-arrow:not([class*='default']) {
    border: none;
    height: auto;
    width: auto;
    svg {
      display: none;
    }
  }

  .mat-mdc-select-arrow::before {
    @extend .material-icons;
    content: 'expand_more';
  }
  .mat-mdc-option {
    font-size: mat.get-theme-typography(
      $ds-typography-config,
      'body-2',
      font-size
    ) !important;
  }

  .mat-mdc-select-panel:not([class*='default']) {
    &,
    & .mat-mdc-form-field-text-suffix {
      font-family: mat.get-theme-typography(
        $ds-typography-config,
        'body-2'
      ) !important;
      font-size: mat.get-theme-typography(
        $ds-typography-config,
        'body-2',
        font-size
      ) !important;
    }
  }

  .mat-mdc-option:not(.mat-mdc-option-disabled) .mdc-list-item__primary-text,
  .mdc-list-item__primary-text {
    color: var(--ds-neutral-900) !important;
    line-height: 1.2;
  }

  .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: var(--ds-neutral-600);
  }

  .mat-mdc-option.mdc-list-item--selected {
    color: var(--ds-neutral-300);
  }

  .mat-primary
    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-disabled):not(
      [class*='default']
    ) {
    color: var(--ds-neutral-900);
    background-color: var(--ds-neutral-50);
  }

  .mat-mdc-option-multiple
    .mat-pseudo-checkbox.mat-pseudo-checkbox-checked:not([class*='default']) {
    background-color: var(--ds-neutral-600);
  }

  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked:not(
      [class*='default']
    ) {
    display: none;
  }
}
