@use 'styles/new-design/mixins/' as mixins;

:root {
  .mdc-dialog {
    --mdc-dialog-subhead-color: var(--ds-info-A100);
    --mdc-dialog-container-shape: var(--ds-border-radius);
    --mdc-dialog-subhead-size: 1.25rem;
  }

  .mat-mdc-dialog-container:not([class*='default']) {
    border-radius: var(--ds-border-radius);
    overflow: hidden;
  }

  .mat-mdc-dialog-content,
  .mat-mdc-dialog-actions,
  .mat-mdc-dialog-title {
    padding: var(--ds-spacing);
  }

  .mat-mdc-dialog-title + .mat-mdc-dialog-content {
    padding-top: var(--ds-spacing) !important;
    margin-top: calc(-1 * var(--ds-spacing)) !important;
  }
  .mat-mdc-dialog-title:not([class*='default']) {
    margin: 0 0 var(--ds-spacing);
    display: block;
    padding-top: 0;
    color: var(--ds-info-A100);
    background: var(--ds-neutral-900);
    font-family: PalfingerHandelVersalien, Helvetica, sans-serif;
  }

  .mat-mdc-dialog-actions:not([class*='default']) {
    @include mixins.actions-container();
    padding-top: calc(0.5 * var(--ds-spacing));
    padding-bottom: calc(0.5 * var(--ds-spacing));
    justify-content: flex-end;
    min-height: unset !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    color: var(--ds-neutral-900);
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
  }

  .mat-mdc-dialog-container .mdc-dialog__title.default {
    color: var(--ds-neutral-900);
  }
}

// fixes the problem described in this open issue:
// https://github.com/angular/components/issues/25054
.cdk-global-scrollblock {
  overflow-y: hidden !important;
}
