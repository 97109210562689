.mat-drawer {
  border-right: none;
}

.mat-drawer-container {
  &,
  h1 {
    background: none;
    color: var(--ds-neutral-900) !important;
  }
}
