@use 'styles/new-design/mixins/elevation' as elevation;

.gm-style .gm-style-iw {
  border-radius: var(--ds-border-radius);
  @include elevation.elevation(5);
}

.map-container {
  width: 100% !important;
}
