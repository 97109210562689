.mat-mdc-slide-toggle.mat-checked:not([class*='default'])
  .mat-mdc-slide-toggle-thumb {
  background-color: var(--ds-neutral-700);
}

.mat-mdc-slide-toggle.mat-checked:not([class*='default'])
  .mat-mdc-slide-toggle-bar {
  background-color: var(--ds-neutral-300);
}

.mat-mdc-slide-toggle-bar:not([class*='default']) {
  background-color: var(--ds-neutral-100);
}

.mat-mdc-slide-toggle-thumb:not([class*='default']) {
  background-color: var(--ds-neutral-50);
}

:root {
  .mat-mdc-slider-ticks:not([class*='default']) {
    background-color: var(--ds-neutral-200);
  }
  mat-slider {
    --mdc-slider-label-container-color: var(--ds-neutral-700);
    --mat-mdc-slider-value-indicator-opacity: 0.9;
  }
  .mat-mdc-slider .mdc-slider__value-indicator-container {
    --mat-slider-value-indicator-opacity: 1;
  }
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, var(--ds-neutral-900));
}
