:root {
  [matlistitemtitle] {
    justify-content: flex-start !important;
  }
  .mat-mdc-list-item {
    padding: calc(0.5 * var(--ds-spacing));
    height: auto !important;
    font-size: 0.875rem !important;
  }

  .mdc-list-item--with-trailing-meta .mdc-list-item__end {
    align-self: center !important;
  }
}
