:root {
  .mat-mdc-card-header {
    h4 {
      margin: 0;
    }
  }

  .mat-mdc-card-title:not([class*='default']) {
    font-family: mat.get-theme-typography(
      $ds-typography-config,
      'headline-5',
      font-family
    );
    font-weight: mat.get-theme-typography(
      $ds-typography-config,
      'body-1',
      font-weight
    );
    font-size: mat.get-theme-typography(
      $ds-typography-config,
      'body-1',
      font-size
    );
    line-height: mat.get-theme-typography(
      $ds-typography-config,
      'body-1',
      line-height
    );
  }

  .mat-mdc-card-subtitle:not([class*='default']) {
    font-size: inherit;
    color: var(--ds-neutral-700);
  }

  .mat-mdc-card-content,
  .mat-mdc-card-image {
    padding-top: calc(0.5 * var(--ds-spacing));
    padding-bottom: calc(0.5 * var(--ds-spacing));
  }
}
