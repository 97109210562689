@use 'styles/new-design/mixins/' as mixins;
$stepperHeaderHeight: calc(3 * var(--ds-spacing));
$h2StepperMarginTop: calc(2 * var(--ds-spacing));

// Remove at 1.August.2024
.mat-stepper-horizontal.deprecated-style,
.mat-stepper-vertical.deprecated-style {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--ds-neutral-10);

  .mat-horizontal-stepper-header-container {
    flex: 0 0 auto;
  }

  .mat-horizontal-stepper-header:not([class*='default']) {
    flex: 1;
    height: $stepperHeaderHeight;
    justify-content: center;
    padding: 0;
    overflow: visible;
  }

  .mat-horizontal-content-container:not([class*='default']) {
    @include mixins.page-spacing();
    padding-top: 0;
    padding-bottom: 0;
    overflow: auto;
    overflow-x: hidden;
    flex: 1 1 auto;
  }

  // this is used to position stepper-sidebar
  .mat-horizontal-stepper-content:not([class*='default']):not(
      .mat-horizontal-stepper-content-inactive
    ) {
    box-sizing: border-box;
    transition: {
      duration: var(--ds-duration);
      timing-function: var(--ds-timing-function);
      property: padding-right;
    }
    height: 100%;

    h2 {
      margin-top: $h2StepperMarginTop;
    }

    &:not(:empty)::after {
      content: '';
      height: calc(2 * var(--ds-spacing));
      display: block;
      width: 100%;
    }
  }

  .mat-horizontal-stepper-content[aria-expanded='false'] {
    padding: 0;
  }

  .mat-step-optional {
    display: none;
  }

  @mixin active-step(
    $colorFrom: var(--ds-neutral-900),
    $colorTo: var(--ds-neutral-700)
  ) {
    cursor: pointer;
    font-weight: unset;
    @include mixins.inclined-gradient($colorFrom, $colorTo);

    &::before {
      border-top: calc(0.5 * $stepperHeaderHeight) solid $colorFrom;
      border-bottom: calc(0.5 * $stepperHeaderHeight) solid $colorFrom;
    }

    @include mixins.media-lt-md {
      @include mixins.gradient($colorFrom, $colorTo);
      &::before {
        display: none;
      }
    }
  }

  .mat-step-header .mat-step-label:not([class*='default']) {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ds-neutral-contrast-700);
    @include mixins.inclined-gradient(
      var(--ds-neutral-400),
      var(--ds-neutral-300)
    );
    overflow: visible;
    cursor: default;

    &::before {
      content: '';
      position: absolute;
      left: calc(-0.5 * var(--ds-spacing));
      width: 0;
      height: 0;
      border-top: calc(0.5 * $stepperHeaderHeight) solid var(--ds-neutral-400);
      border-bottom: calc(0.5 * $stepperHeaderHeight) solid
        var(--ds-neutral-400);
      border-left: calc(0.5 * var(--ds-spacing)) solid transparent;
    }

    &.mat-step-label-active {
      @include active-step();
    }

    &.mat-step-label-selected {
      @include active-step(var(--ds-primary-900), var(--ds-primary-700));
      font-size: 0.875rem;
    }
  }

  .mat-horizontal-stepper-header:first-of-type .mat-step-label::before {
    display: none;
  }

  .mat-step-icon:not([class*='default']) {
    display: none;
  }

  .mat-stepper-horizontal-line:not([class*='default']) {
    flex: 0;
    min-width: 0;
    margin: 0;
  }
}

.mat-stepper-vertical:not([class*='default']),
.mat-stepper-horizontal:not([class*='default']) {
  background: var(--ds-neutral-10);
  height: 100%;

  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    background-color: var(--ds-success-500);
    color: var(--ds-white);
  }

  .mat-step-icon-selected {
    background-color: var(--ds-info-500);
    color: var(--ds-white);
  }
}

.mat-stepper-vertical:not([class*='default']) {
  .mat-step-header[ng-reflect-active='true']:not([ng-reflect-selected='true'])
    + .mat-vertical-content-container::before {
    border-left-color: var(--ds-success-500);
    border-width: 2px;
  }
}

.mat-stepper-horizontal:not([class*='default']) {
  .mat-horizontal-stepper-wrapper {
    height: 100%;
  }
  .mat-horizontal-stepper-header-container:not([class*='default']) {
    flex: 0 0 auto;
  }
  .mat-horizontal-content-container:not([class*='default']) {
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
  }

  div.mat-horizontal-stepper-content:not(
      [class*='mat-horizontal-stepper-content-inactive']
    ) {
    height: 100%;
  }

  .mat-stepper-horizontal-line:has(~ .mat-step-header[aria-selected='true']) {
    border-top-color: var(--ds-success-500);
    border-width: 2px;
  }
}
