.spotlight {
  border: 2px solid var(--ds-info-500);
  animation: pulse 2s infinite;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--ds-info-500);
  }

  100% {
    box-shadow: 0 0 0 calc(0.5 * var(--ds-spacing)) #{var(--ds-info-500) + '00'};
  }
}
