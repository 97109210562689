@use 'styles/new-design/mixins/' as mixins;

.ribbon {
  @include mixins.box-ribbon();
}

.neutral-ribbon {
  @include mixins.box-ribbon(var(--ds-neutral-400), var(--ds-neutral-500));
}

.info-ribbon {
  @include mixins.box-ribbon(var(--ds-info-300), var(--ds-info-400));
}

.success-ribbon {
  @include mixins.box-ribbon(var(--ds-success-400), var(--ds-success-500));
}

.warning-ribbon {
  @include mixins.box-ribbon(var(--ds-warning-200), var(--ds-warning-300));
}

.error-ribbon {
  @include mixins.box-ribbon(var(--ds-error-400), var(--ds-error-500));
}
