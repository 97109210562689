.mat-badge-content:not([class*='default']) {
  width: auto;
  padding: 0 calc(0.25 * var(--ds-spacing));
  font-weight: normal;
}

.mat-badge-medium .mat-badge-content:not([class*='default']) {
  height: var(--ds-spacing);
  line-height: var(--ds-spacing);
  border-radius: var(--ds-spacing);
  min-width: calc(0.5 * var(--ds-spacing));
}

.mat-badge-small .mat-badge-content:not([class*='default']) {
  height: calc(0.75 * var(--ds-spacing));
  line-height: calc(0.75 * var(--ds-spacing));
  border-radius: calc(0.75 * var(--ds-spacing));
  min-width: calc(0.75 * var(--ds-spacing));
  box-sizing: border-box;
}
