@media print {
  body {
    height: auto;
  }
  .pd-table,
  .mat-mdc-table {
    td {
      color: var(--ds-neutral-900) !important;
    }
    .mat-mdc-form-field:not([class*='default']) {
      width: 100%;
      .mat-mdc-form-field-infix {
        width: 0;
      }
    }
  }

  ds-breadcrumbs {
    display: none;
  }

  .mat-form-field-appearance-outline:not([class*='default']) {
    /* remove borders to make inputs look like text */
    .mdc-text-field--outlined {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border: none;
      }
    }

    /* no border, no padding needed here*/
    .mat-mdc-form-field-flex {
      padding: 0;
    }

    /* make all labels float to give a continously look */
    .mat-mdc-form-field-label {
      transform: translateY(-1.34375em) !important;
    }

    /* default text color on input - even if disabled = readonly */
    &.mat-mdc-form-field-disabled .mat-mdc-form-field-outline {
      color: var(--ds-neutral-900);
    }
    .mat-input-element:disabled,
    .mat-mdc-form-field-type-mat-native-select.mat-mdc-form-field-disabled
      .mat-mdc-form-field-infix::after {
      color: var(--ds-neutral-900);
    }

    /* hide interactive elements */
    mat-datepicker-toggle,
    .mat-mdc-select-arrow-wrapper,
    .mat-mdc-form-field-required-marker {
      display: none;
    }
  }

  .mat-mdc-card {
    box-shadow: none !important;
    border: 1px solid var(--ds-neutral-900) !important;
  }

  .mat-mdc-snack-bar-container {
    display: none !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
