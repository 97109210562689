table {
  border-spacing: 0;
}

table.pd-table:not([class*='default']),
table.mat-mdc-table:not([class*='default']) {
  border-spacing: 0;
  background: none;
}

table.mat-mdc-table thead tr,
table.pd-table thead tr,
tr.mat-mdc-header-row:not([class*='default']) {
  height: calc(3 * var(--ds-spacing));
  background: var(--ds-neutral-contrast-500) !important;
}

table.mat-mdc-table tbody tr,
table.mat-mdc-table tfoot tr,
table.pd-table tbody tr,
table.pd-table tfoot tr,
tr.mat-mdc-row:not([class*='default']),
tr.mat-mdc-footer-row:not([class*='default']) {
  height: calc(2.5 * var(--ds-spacing));
}

table.mat-mdc-table thead th,
table.pd-table thead th,
th.mat-mdc-header-cell:not([class*='default']) {
  font-size: inherit;
  color: inherit;
  font-weight: 500;
  &:not([class*='text']) {
    text-align: left;
  }
}

ds-table tr:hover:not(.no-hover-effect) td,
.pd-table tr:hover:not(.no-hover-effect) td,
ds-table tr.mat-mdc-row:hover:not(.no-hover-effect),
.pd-table tr.mat-mdc-row:hover:not(.no-hover-effect),
ds-table tr.mat-mdc-row:hover:not(.no-hover-effect) .mat-mdc-table-sticky,
.pd-table tr.mat-mdc-row:hover:not(.no-hover-effect) .mat-mdc-table-sticky,
ds-table tr.mat-row:hover:not(.no-hover-effect) .mat-table-sticky,
.pd-table tr.mat-row:hover:not(.no-hover-effect) .mat-table-sticky {
  background: var(--ds-neutral-50) !important;
  opacity: 100%;
  transition: {
    duration: var(--ds-duration);
    transition-property: background;
  }
}

table.mat-mdc-table thead th,
table.mat-mdc-table td,
table.pd-table thead th,
table.pd-table td,
th.mat-mdc-header-cell:not([class*='default']),
td.mat-mdc-cell:not([class*='default']),
td.mat-mdc-footer-cell:not([class*='default']) {
  padding: calc(0.5 * var(--ds-spacing));
  border-width: 0 0 1px;
  border-style: solid;
  border-color: var(--ds-neutral-50);
  &:not([class*='white-space']) {
    white-space: nowrap;
  }

  & > .mat-mdc-icon-button {
    vertical-align: inherit;
  }
}

table.mat-mdc-table tfoot td,
table.pd-table tfoot td {
  border-width: 1px 0 0;
}

table.mat-mdc-table tr th:first-child,
table.mat-mdc-table tr td:first-child,
table.pd-table tr th:first-child,
table.pd-table tr td:first-child,
th.mat-mdc-header-cell:first-child:not([class*='default']),
td.mat-mdc-cell:first-child:not([class*='default']),
td.mat-mdc-footer-cell:first-child:not([class*='default']) {
  padding-left: var(--ds-spacing);
}

table.mat-mdc-table th:last-child,
table.mat-mdc-table td:last-child,
table.pd-table th:last-child,
table.pd-table td:last-child {
  &:not([class*='default']):not(.mat-action-cell) {
    padding-right: var(--ds-spacing);
    white-space: nowrap;
  }
}

table.mat-mdc-table tbody tr:last-child,
table.pd-table tbody tr:last-child,
tr.mat-mdc-row:last-child:not([class*='default']),
tr.mat-mdc-header-row:last-child:not([class*='default']),
tr.mat-mdc-footer-row:last-child:not([class*='default']) {
  td {
    border-width: 0;
  }
}

tr.mat-mdc-footer-row:last-child
  td.mat-mdc-table-sticky:not([class*='default']),
tr.mat-footer-row:last-child td.mat-table-sticky:not([class*='default']),
tr.mat-mdc-footer-row:last-child td.mat-mdc-footer-cell:not([class*='default']),
tr.mat-footer-row:last-child td.mat-table-sticky:not([class*='default']) {
  border-width: 1px 0 0;
}
td.mat-column-action {
  width: 1%;
  padding-right: calc(0.5 * var(--ds-spacing)) !important;
  white-space: nowrap;
}
