@use '../mixins/elevation' as elevation;

table {
  width: 100%;

  // STICKY
  .mat-table-sticky,
  .mat-mdc-table-sticky {
    background: var(--ds-info-A100);
  }

  tbody td.mat-mdc-cell.mat-mdc-table-sticky,
  tbody td.mat-cell.mat-table-sticky {
    width: 1%;
    @include elevation.elevation(1, right);

    &:last-child {
      padding-right: calc(0.5 * var(--ds-spacing));
      @include elevation.elevation(1);
    }
  }

  thead th.mat-mdc-header-cell.mat-mdc-table-sticky:last-child,
  thead th.mat-header-cell.mat-table-sticky:last-child {
    padding-right: calc(0.5 * var(--ds-spacing));

    &:not([class*='text-']) {
      text-align: center;
    }
  }

  // INTERACTABLE
  td.mat-action-cell,
  td.mat-mdc-cell.mat-action-cell:not([class*='default']),
  mat-form-field {
    color: var(--ds-neutral-900);
  }

  td.mat-action-cell,
  td.mat-mdc-cell.mat-action-cell:not([class*='default']) {
    width: 0.0001%;
    padding-right: calc(0.5 * var(--ds-spacing));
  }

  // HEADER
  th {
    mat-hint {
      font-size: 75%;
      margin-bottom: -1em;
      margin-top: -0.75em;
      display: block;
      line-height: 1;
    }

    &.mat-mdc-header-cell {
      &.text-right {
        text-align: right;

        .mat-sort-header-content {
          text-align: right;
        }
      }

      &.text-center {
        text-align: center;

        .mat-sort-header-content {
          text-align: center;
        }
      }
    }
  }

  // LIST
  &[list] {
    > span {
      font-weight: 500;
      display: table-caption;
      width: 100%;
      margin-bottom: calc(0.5 * var(--ds-spacing));
    }

    td,
    th {
      vertical-align: top;
      padding-bottom: calc(0.5 * var(--ds-spacing));

      &:first-child {
        padding-right: calc(0.5 * var(--ds-spacing));
      }
    }

    tr > td:nth-child(odd) {
      font-weight: 500;
      color: var(--ds-neutral-700);
      white-space: nowrap;
      padding-right: calc(0.5 * var(--ds-spacing));
    }
  }

  // MIN WIDTH CELL
  .min-width-cell {
    width: 1%;
  }
}

.mat-sort-header-content {
  display: block !important;
}
.text-right .mat-sort-header-content {
  text-align: right;
}

.table-column-group-header {
  text-align: center !important;
  &:not(:first-child) {
    border-left-width: 1px !important;
  }
}
.ds-table-group-border {
  border-left-width: 1px !important;
}

.pd.mat-icon {
  color: var(--ds-neutral-900);
}
