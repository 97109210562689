.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__content {
  pointer-events: all;
  letter-spacing: normal;
}

.mat-mdc-tab-header {
  background-color: var(--ds-info-A100);
  border-bottom: 2px solid var(--ds-neutral-50);
}

.mat-mdc-tab .mdc-tab__content .mdc-tab__text-label {
  color: var(--ds-neutral-900);
}
