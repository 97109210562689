@use './gradients.scss' as gradients;

@mixin box-ribbon(
  $colorFrom: var(--ds-primary-400),
  $colorTo: var(--ds-primary-500)
) {
  position: relative;
  overflow: hidden;
  &:after {
    content: ' ';
    position: absolute;
    width: 4px !important;
    left: 0;
    top: 0;
    bottom: 0;
    @include gradients.gradient($colorFrom, $colorTo);
  }
}
