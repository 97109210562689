.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.padding {
  padding: var(--ds-spacing);
}

.padding-top {
  padding-top: var(--ds-spacing);
}

.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.mini-padding {
  padding: calc(0.5 * var(--ds-spacing));
}
.no-min-height {
  min-height: 0;
}
.normal-white-space {
  white-space: normal !important;
}
