.loading {
  &.mat-progress-bar {
    bottom: calc(0.2 * var(--ds-spacing));
    margin-bottom: calc(-0.2 * var(--ds-spacing));
  }
  .mat-progress-bar-buffer {
    background-color: var(--ds-info-100);
  }
  .mat-progress-bar-fill::after {
    background-color: var(--ds-info-600);
  }
}
