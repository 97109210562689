@use 'styles/new-design/mixins/media-queries' as queries;

@include queries.media-lt-md {
  // button
  .mat-mdc-button-touch-target {
    padding: 8px;
  }

  // radio buttons
  .mat-mdc-radio-touch-target {
    padding: 12px;
  }

  // checkboxes
  .mat-mdc-checkbox-touch-target {
    padding: 12px;
  }
}

.large-touch-target {
  padding: 12px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.standard-touch-target {
  padding: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
