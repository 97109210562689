.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: uppercase;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
}

.nowrap-text {
  white-space: nowrap;
}

.inherit-color {
  color: inherit;
}

div[pair] {
  width: 100%;
  display: flex;
  flex-direction: column;
  *[title] {
    color: var(--ds-neutral-700);
    font-weight: 500;
  }
  *:last-child {
    font-weight: normal;
  }
}
mat-optgroup {
  mat-option,
  .mat-optgroup-label {
    font-size: inherit !important;
    font-family: 'Roboto' !important;
  }
}
