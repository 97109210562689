html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: var(--ds-neutral-10);
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: var(--ds-neutral-50);
  -webkit-print-color-adjust: exact;

  padding: calc(0.25 * var(--ds-spacing));
  display: inline-block;
  font-size: 1rem;

  strong {
    font-weight: 600;
  }
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}
.bigger-font-tooltip {
  font-size: 0.8rem;
}
