@use 'styles/new-design/mixins' as mixins;

:root {
  .mat-mdc-snack-bar-container:not([class*='default']) {
    position: absolute;
    background: var(--ds-neutral-contrast-500);
    border-radius: var(--ds-border-radius);
    color: unset;
    max-width: calc(100% - 2.8 * var(--ds-spacing)) !important;
    margin: 0;
    bottom: var(--ds-spacing);
    left: var(--ds-spacing);
    right: auto;
    --mat-mdc-snack-bar-button-color: var(--ds-neutral-contrast-500);

    ds-text-indicator {
      overflow: hidden;
      span {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    @include mixins.media-xs {
      left: var(--ds-spacing);
      bottom: var(--ds-spacing);
      right: var(--ds-spacing);
    }
  }

  .mdc-snackbar__surface {
    padding-right: 0;
  }

  .mat-mdc-snack-bar-container.snackbar-with-component {
    padding: 0;
  }
}
