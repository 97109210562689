@use '../mixins' as mixins;

h1:not([class*='default']),
.h1 {
  margin-top: calc(2 * var(--ds-spacing));
  margin-bottom: var(--ds-spacing);
  @include mixins.media-xs {
    margin-top: calc(0.5 * var(--ds-spacing));
    margin-bottom: calc(0.5 * var(--ds-spacing));
  }
}

// this is used to position stepper-sidebar
$h2MarginTop: calc(2 * var(--ds-spacing));
$h2MarginBottom: var(--ds-spacing);
h2:not([class*='default']),
.h2 {
  margin-top: $h2MarginTop;
  margin-bottom: $h2MarginBottom;
}

h3:not([class*='default']),
.h3 {
  margin-top: calc(1.5 * var(--ds-spacing));
  margin-bottom: calc(0.5 * var(--ds-spacing));
}

h4:not([class*='default']),
.h4 {
  margin-top: calc(1.5 * var(--ds-spacing));
  margin-bottom: calc(0.25 * var(--ds-spacing));
}

h5:not([class*='default']),
.h5 {
  margin-top: var(--ds-spacing);
  margin-bottom: 0;
}

h6:not([class*='default']),
.h6 {
  margin-top: calc(0.5 * var(--ds-spacing));
  margin-bottom: calc(0.5 * var(--ds-spacing));
}
