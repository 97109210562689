@use 'styles/new-design/mixins' as mixins;

$stepperSidebarSize: calc(18 * var(--ds-spacing));

.stepper-with-sidebar-content {
  padding-right: $stepperSidebarSize;

  @include mixins.media-lt-md {
    padding-right: unset;
  }
}

ds-deprecated-responsive-stepper {
  .mat-stepper-horizontal {
    height: auto;

    .mat-horizontal-stepper-content {
      padding: 0;
    }
  }
}
.deprecated-style {
  @include mixins.media-lt-md {
    .mat-horizontal-stepper-header-container:not([class*='default']) {
      align-items: stretch;
      flex-direction: column;
      display: block;
    }

    .mat-stepper-horizontal-line {
      display: none;
    }
  }
}
