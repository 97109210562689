@use 'sass:math';

@mixin elevation(
  $zValue,
  $direction: center,
  $size: var(--ds-spacing),
  $color: black
) {
  $opacity: 0.02 + 0.01 * $zValue;
  @if $direction == center {
    box-shadow: 0px
      #{calc(0.5 * $size)}
      #{$size}
      #{calc(0.5 * $size)}
      rgba($color, $opacity);
  } @else if $direction == right {
    $sizeTenth: math.div($size, 10);
    box-shadow: $size
      #{calc(0.5 * $size)}
      #{$size}
      #{$sizeTenth}
      rgba($color, $opacity);
  } @else if $direction == left {
    $sizeTenth: math.div($size, 10);
    box-shadow: #{calc(-1 * $size)}
      0px
      #{$size}
      #{$sizeTenth}
      rgba($color, $opacity);
  } @else if $direction == top {
    box-shadow: 0px
      #{calc(-1 * $size)}
      #{$size}
      #{calc(0.25 * $size)}
      rgba($color, $opacity);
  } @else {
    @error 'Unknown direction #{$direction}. For defaut set center as direction attribute';
  }
}
