@use 'styles/new-design/mixins' as mixins;

.mat-mdc-paginator:not([class*='default']) {
  color: var(--ds-neutral-900);
  background: none;

  [disabled],
  button[disabled] {
    color: var(--ds-neutral-300);
  }
  .mat-mdc-select {
    line-height: 1;
  }
}

.mat-mdc-paginator:not([class*='default']):not(.no-mobile) {
  @include mixins.media-lt-md {
    .mat-mdc-paginator-range-label {
      order: 2;
      margin: 0 var(--ds-spacing);
    }

    .mat-mdc-paginator-navigation-previous {
      order: 1;
      @include mixins.micro-fab(48px);
    }

    .mat-mdc-paginator-navigation-next {
      order: 3;
      @include mixins.micro-fab(48px);
    }

    .mat-mdc-paginator-page-size {
      display: none;
    }

    .mat-mdc-paginator-navigation-first {
      display: none;
    }

    .mat-mdc-paginator-navigation-last {
      display: none;
    }

    .mat-mdc-paginator-container {
      display: flex;
      justify-content: center;
      padding-bottom: var(--ds-spacing);
    }
  }
}
