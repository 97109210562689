@use '@angular/material';
@use 'colors.scss' as colors;

:root {
  //Spacing
  --ds-spacing: 20px;
  --ds-border-radius: 2px;
  --ds-icon-size: 24px;

  //Animations Source: https://github.com/angular/components/blob/main/src/material/core/style/_variables.scss
  --ds-duration: 500ms;
  --ds-timing-function: cubic-bezier(0.35, 0, 0.25, 1);

  //Header height
  --ds-header-height: calc(2.85 * var(--ds-spacing));
  --ds-header-mobile-height: calc(4.25 * var(--ds-spacing));
  //HC Banner height
  --ds-banner-desktop-height: calc(2.8 * var(--ds-spacing));
  --ds-banner-mobile-height: calc(4.1 * var(--ds-spacing));

  //Colors
  --ds-white: #fff;
  --ds-black: #000;
  @each $key, $palette in colors.$colors {
    @each $item, $color in $palette {
      @each $shade, $value in $color {
        @if $item == 'contrast' {
          @each $contrast-shade in $value {
            --ds-#{$key}-contrast-#{$shade}: #{$contrast-shade};
          }
        } @else {
          --ds-#{$key}-#{$item}: #{$shade};
        }
      }
    }
  }
}
