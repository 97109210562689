@use '../../mixins' as mixins;

.info,
table.mat-mdc-table .info {
  @include mixins.gradient(var(--ds-info-400), var(--ds-info-500));
  color: var(--ds-info-contrast-500);
}

.info-text,
table.mat-mdc-table .info-text {
  color: var(--ds-info-500) !important;
}

.success,
table.mat-mdc-table .success {
  @include mixins.gradient(var(--ds-success-600), var(--ds-success-700));
  color: var(--ds-success-contrast-500);
}

.success-text,
table.mat-mdc-table .success-text {
  color: var(--ds-success-500) !important;
}

.primary-text,
table.mat-mdc-table .primary-text {
  color: var(--ds-primary-600) !important;
}

.warning,
table.mat-mdc-table .warning {
  @include mixins.gradient(var(--ds-warning-400), var(--ds-warning-500));
  color: var(--ds-warning-contrast-500);
}

.warning-text,
table.mat-mdc-table .warning-text {
  color: var(--ds-warning-500) !important;
}

.error,
table.mat-mdc-table .error {
  @include mixins.gradient(var(--ds-error-600), var(--ds-error-500));
  color: var(--ds-error-contrast-500);
}

.error-text,
table.mat-mdc-table .error-text {
  color: var(--ds-error-500) !important;
}

table.mat-mdc-table .error-text,
table.mat-mdc-table .warning-text,
table.mat-mdc-table .success-text,
table.mat-mdc-table .info-text {
  font-weight: 500;
}

.neutral,
table.mat-mdc-table .neutral {
  @include mixins.gradient();
  color: var(--ds-neutral-contrast-500);
}

mat-icon[mini-icon] {
  font-size: 18px;
  width: 18px;
  height: 18px;
}
