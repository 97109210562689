@mixin gradient(
  $colorFrom: var(--ds-neutral-400),
  $colorTo: var(--ds-neutral-500)
) {
  @include linear-gradient(180deg, $colorFrom, $colorTo);
}

@mixin inclined-gradient(
  $colorFrom: var(--ds-neutral-900),
  $colorTo: var(--ds-neutral-700)
) {
  @include linear-gradient(75deg, $colorFrom, $colorTo);
}

@mixin cta-gradient() {
  @include inclined-gradient();
}

@mixin skew-gradient(
  $colorFrom: var(--ds-neutral-900),
  $colorTo: var(--ds-neutral-700),
  $angle: -45deg
) {
  @include linear-gradient($angle, $colorFrom 50%, $colorTo 50%);
}

@mixin linear-gradient($angle, $color1, $color2) {
  background: linear-gradient($angle, $color1, $color2);
  @media print {
    background: linear-gradient($angle, $color1, $color2);
    -webkit-print-color-adjust: exact;
  }
}
