// angular sets the height of the header to 48px via code,
// but we want it to be 2.5 * spacing (3.5 expanded), so we set the top and bottom
// padding to give us this total height.
$headerPaddingTopBottom: calc((2.5 * var(--ds-spacing) - 48px) / 2);
$headerPaddingTopBottomExpanded: calc((3.5 * var(--ds-spacing) - 64px) / 2);

.mat-expansion-panel:not([class*='default']) {
  border-bottom: 1px solid var(--ds-neutral-50);

  .mat-expansion-panel-header {
    font-size: inherit;
    padding: $headerPaddingTopBottom var(--ds-spacing);

    &.mat-expanded {
      padding: $headerPaddingTopBottomExpanded var(--ds-spacing);
    }

    .mat-content {
      margin-right: var(--ds-spacing);
    }
  }

  .mat-expansion-panel-body {
    padding: 0 var(--ds-spacing) var(--ds-spacing);
  }
}

.mat-expansion-panel:last-of-type,
.mat-expansion-panel.mat-expanded {
  border-bottom: 0;
}

mat-card .mat-accordion {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
    border-bottom: 1px solid var(--ds-neutral-50);
  }

  .mat-expansion-panel:first-of-type {
    border-top: 1px solid var(--ds-neutral-50);
  }

  .mat-expansion-panel {
    border-radius: 0;
  }

  .mat-expansion-panel:first-of-type {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }
}
